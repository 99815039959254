import gql from "graphql-tag";

export const VOCABULARY_ENTRY_FRAGMENT = gql`
	fragment VocabularyEntry_Fragment on VocabularyEntry {
		id
		title
		keyword
		index
		parent {
			id
			keyword
		}
	}
`;

export const CATEGORY_FRAGMENT = gql`
	fragment Category_Fragment on Category {
		id
		title
		keyword
		parent {
			id
			keyword
		}
		linkedCategories
		retailerId
	}
`;
export const STATE_FRAGMENT = gql`
	fragment State_Fragment on State {
		id
		name
		region
		abbreviation
		country
	}
`;

export const RETAILER_FRAGMENT = gql`
	fragment Retailer_Fragment on Retailer {
		id
		name
		code
	}
`;

export const WHITE_LABEL_CONFIG_FRAGMENT = gql`
	fragment WhiteLabelConfig_Fragment on WhiteLabelConfig {
		title
		name
		componentConfig {
			showFooter
			showHeader
		}
		cssStyle {
			cssClasses
		}
		images {
			imagePath
			imageType
		}
	}
`;

export const GET_STATIC_CONTEXT = gql`
	query getStaticContext($domain: String!) {
		getVocabulary {
			success
			data {
				...VocabularyEntry_Fragment
			}
			error {
				code
			}
		}
		getAllCategories {
			success
			data {
				...Category_Fragment
			}
			error {
				code
			}
		}
		findAllRetailers {
			success
			data {
				...Retailer_Fragment
			}
		}
		findAllState {
			success
			data {
				...State_Fragment
			}
		}
		getConfigByDomain(domain: $domain) {
			data {
				...WhiteLabelConfig_Fragment
			}
			success
		}
	}
	${VOCABULARY_ENTRY_FRAGMENT}
	${CATEGORY_FRAGMENT}
	${RETAILER_FRAGMENT}
	${STATE_FRAGMENT}
	${WHITE_LABEL_CONFIG_FRAGMENT}
`;
