import Image from "next/image";
import hero from "../public/404.png";
import Link from "next/link";
import ButtonMain from "../src/UI/Button/ButtonMain";

interface MyCustom404PageProps {
	text?: string;
}

const MyCustom404Page = ({
	text = "Sorry, the content you are looking for could not be found.",
}: MyCustom404PageProps) => {
	return (
		<div className="box404">
			<div className="hero404">
				<Image
					src={hero}
					alt="hero"
					layout="responsive"
					//objectFit="cover"
					quality={100}
				/>
			</div>

			<div className="content404">
				<span>{text}</span>
				<ButtonMain
					title={"Go To Home Page"}
					href={"/"}
					className={"button404"}
				/>
			</div>
		</div>
	);
};

export default MyCustom404Page;
