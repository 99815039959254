import Link from "next/link";

interface ButtonMainProps {
	title: string;
	href: string;
	className: string;
}

function ButtonMain({ title, href, className }: ButtonMainProps): JSX.Element {
	return (
		<Link href={href} passHref>
			<button type="button" className={className}>
				{title}
			</button>
		</Link>
	);
}

export default ButtonMain;
