import Script from "next/script";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import StaticContextProvider from "../src/context/static-context/static-context";
import withUrqlClient from "../src/graphql/client";
import absoluteUrl from "next-absolute-url";
import { AppContext } from "next/app";
import TagManager from "react-gtm-module";

import * as ga from "../helpers/ga";
import * as om from "../helpers/om";
import * as activecampaign from "../helpers/activecampaign";
// Global Styles
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/globals.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/css/pagination";

import dynamic from "next/dynamic";
import type { AppProps } from "next/app";
import MyCustom404Page from "./404";
import { CookiesProvider } from "react-cookie";
const Layout = dynamic(() => import("../src/components/Layout/Layout"));

console.log(process.env.GRAPH_URL_STRAPIO, "process.env.GRAPH_URL_STRAPIO");

// const tagManagerArgs = {
// 	gtmId: "GTM-TNC5R6F",
// }

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter();
	const [gaKey, setGaKey] = useState<string>("");

	useEffect(() => {
		const handleRouteChange = (url: any) => {
			ga.pageview(url);
			om.reset(url);
			activecampaign.update();
		};
		//When the component is mounted, subscribe to router changes
		//and log those page views
		router.events.on("routeChangeComplete", handleRouteChange);

		// If the component is unmounted, unsubscribe
		// from the event with the `off` method
		return () => {
			router.events.off("routeChangeComplete", handleRouteChange);
		};
	}, [router.events]);

	// useEffect(() => {
	// 	TagManager.initialize(tagManagerArgs);
	// }, []);

	// if (router.pathname === "/_error") {
	// 	return (
	// 		<Layout>
	// 			<MyCustom500Page />
	// 		</Layout>
	// 	);
	// }

	const renderCustom = () => {
		if (router.pathname === "/_error") {
			return <MyCustom404Page />;
		} else {
			return <Component {...pageProps} />;
		}
	};

	return (
		<StaticContextProvider host={pageProps.host}>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/jquery-visible/1.2.0/jquery.visible.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.8.0/gsap.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.8.0/CSSRulePlugin.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/aos/2.3.4/aos.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/js/bootstrap.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"
				strategy="beforeInteractive"
			/>
			<Script
				src="https://unpkg.com/scrollreveal"
				strategy="beforeInteractive"
			></Script>
			<Script
				src="https://cdnjs.cloudflare.com/ajax/libs/typed.js/2.0.0/typed.min.js"
				strategy="beforeInteractive"
			></Script>
			<Script
				src="https://flickity.metafizzy.co/flickity.pkgd.min.js"
				strategy="beforeInteractive"
			/>
			<Script src="/js/main.js" strategy="lazyOnload" />
			<Script src="/js/inventory.js" strategy="lazyOnload" />

			<Script
				id="hs-script-loader"
				src="https://js.hs-scripts.com/23191308.js"
				strategy="lazyOnload"
			/>

			{/* Opt In Monster */}
			{/* <Script
				strategy="lazyOnload"
				id="monster-scripts"
				dangerouslySetInnerHTML={{
					__html: `
					(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,151779,165413);
				`,
				}}
			/> */}

			{/* Live Chat Active Campaign */}
			{/* <Script
				strategy="lazyOnload"
				id="activecampaign-scripts"
				dangerouslySetInnerHTML={{
					__html: `
						(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
						vgo('setAccount', '91281914');
						vgo('setTrackByDefault', true);
						vgo('process');
					`,
				}}
			/> */}

			{/* Global Site Tag (gtag.js) - Google Analytics */}
			<Script
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=UA-155664733-1`}
			/>
			<Script
				strategy="afterInteractive"
				id="ga-script"
				dangerouslySetInnerHTML={{
					__html: `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'UA-155664733-1', {
					page_path: window.location.pathname,
				});
			`,
				}}
			/>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`
				(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
				})(window,document,'script','dataLayer','GTM-NMHX5XP');
			`}
			</Script>

			<Layout>
				{/* <Component {...pageProps} /> */}
				{renderCustom()}
			</Layout>
		</StaticContextProvider>
	);
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { protocol, host } = absoluteUrl(appContext.ctx.req);
	return {
		pageProps: {
			host,
		},
	};
};

//@ts-ignore
export default withUrqlClient(MyApp);
