declare const window: any;
// log the pageview with their URL
export const reset = (url: any) => {
	// const accountID = process.env.NEXT_PUBLIC_OM_ACCOUNTID;
	// const userID = process.env.NEXT_PUBLIC_OM_USERID;
	const accountID = "165413";
	const userID = "151779";

	const om_user = "om" + accountID + "_" + userID;

	if (window[om_user]) {
		window[om_user].reset();
	}
};
